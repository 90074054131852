import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { pipesToSpan } from "../util/common";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import Faq, { FaqCard } from "../components/faq";
import ParagraphTitleTextImage from "../components/paragraphs/title-text-image";
import ParagraphGenericBlockHeroLp from "../components/paragraphs/generic-bloc-hero-lp";
import BlockContentTestimonial from "../components/blocks/content-testimonial";
import ParagraphHubspot from "../components/paragraphs/hubspot";
import ParagraphZebra from "../components/paragraphs/zebra";
import { AppearV2 } from "../components/appear";


function Landing({ data: { node }, path }) {
  const {
    block2Description,
    block2Title,
    block3Title,
    block1Title,
    relationships,
  } = node;

  const {
    faq,
    hero,
    block,
    items,
    blocks,
    cards,
    hubspot,
    testimonials,
    zebra,
  } = relationships;

  //console.log( block)
  const seo = getMetatags(
    node.metatag,
    node.relationships.OGImage?.localFile?.publicURL
  );

  seo.meta.push({
    name: "robots",
    content: "noindex, nofollow",
  });

  //console.log( hero )
  //console.log(ParagraphTitleTextImage)
  return (
    <Layout showCta={false} showMenus={false} showFooter={false}>
      <Seo {...seo} />

      <div className="h-[70px]"></div>

      <ParagraphGenericBlockHeroLp data={hero} />

      {blocks.length > 0 && <div className="h-2.5 md:h-[63px]"></div>}

     {blocks.length > 0 && <section className="px-2.5 md:px-0">
        <SectionCards1
          title={block1Title}
          items={blocks.map((block, index) => ({
            ...block,
            index: `0${index + 1}`,
          }))}
          Card={ParagraphTitleTextImage}
          bodyClass="flex flex-col md:flex-row justify-between gap-5 md:gap-[50px] "
          cardClass="p-[30px] bg-white rounded-[15px] space-y-[30px] md:w-1/3  "
        />
      </section>}

      {blocks.length > 0 && <div className="h-10 md:h-[125px]" />}

      <SectionCards2
        title={block2Title}
        description={block2Description}
        items={items}
        Card={ParagraphTitleTextImage}
        cardClass="slidein"
      />

      <div className="h-[18px]" />


      { !!zebra &&
      <section className="py-12 md:py-32 bg-[#F9F8F7] ">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-0 ">
          <ParagraphZebra data={zebra} className="md:space-y-10" />
        </div>
      </section>}

      {/* <div className="h-[18px]" /> */}


      <section className="pt-24" id="form">
        
        <ParagraphHubspot data={hubspot} >
          <div className="--md:ml-[31px] mt-8 md:mt-0 space-y-[34px]">
            <SectionCards.Title
              title={block.title}
              className="text-[50px] font-semibold leading-[55px] tracking-[-0.02em] text-white"
              secondClassName="text-[50px] font-semibold leading-[55px] tracking-[-0.02em] text-transparent bg-clip-text bg-gradient-to-r from-[#FFFFFF] via-[#A4B8FF] to-[#FFFFFF]"
            />

            <div className="text-lg font-[400] leading-[27px] max-w-[308px] text-white">
              {block.description}
            </div>

            <div className="text-lg font-[400] leading-[27px] max-w-[308px] text-white"
            dangerouslySetInnerHTML={{__html: block?.description_html?.processed}}
            />
              

          </div>
        </ParagraphHubspot>
      </section>

      <SectionCards3
        title={block3Title}
        items={cards}
        Card={ParagraphTitleTextImage}
        className=""
        bodyClass=" mx-auto flex flex-col md:flex-row justify-between items-top max-w-[1048px] bg-[#F9FAFB] md:bg-transparent"
      />


      <section className="py-6 md:py-10">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-0">
          <BlockContentTestimonial data={testimonials} />
        </div>
      </section>

      <section className="py-12 lg:py-20 mx-4">
        <Faq
          title={faq.title}
          items={faq.relationships.questions}
          className="container mx-auto lg:w-[1108px]"
          titleClassName="text-[32px] md:text-[50px] font-medium mb-6 leading-[40px] md:leading-[62px]"
          Card={FaqCard}
        />
      </section>


      {/* <section className="bg-[#F9FAFB]">
        <SectionCards.Body
          className="container lg:px-0 mx-auto py-12 grid grid-cols-1 md:grid-cols-3 justify-between gap-[29px] max-w-[1108px]"
          cardClass="border border-[#D7DFE9] rounded-[10px] px-2.5 pt-2.5 pb-[30px] bg-white space-y-5 "
          items={articles.map((article) => ({
            ...article,
            image: {
              ...article.image,
              src: article.relationships?.image?.localFile?.publicURL,
            },
            category: getCategory(article),
          }))}
          Card={ArticleCard}
        />
      </section> */}

      <AppearV2 />
      
    </Layout>
  );
}

// const getCategory = (article) => {
//   let category = "-";

//   const type = article?.node_type?.type;

//   //console.log(article.type)
//   switch (type) {
//     case "blog":
//       category = article.relationships?.category?.name;
//       break;
//     case "ebook":
//       category = article.type;
//       break;

//     default:
//       category = "--";
//       break;
//   }

//   return category;
// };

const SectionCards = ({
  title,
  description = "",
  items,
  Card,
  bodyClass = "",
  cardClass = "",
}) => {
  return (
    <section className="py-12">
      <div className="container mx-auto">
        {/* <h3>{title}</h3> */}
        <SectionCards.Title title={title} />
        <div>{description}</div>
        <div className={bodyClass}>
          {items.map((item, index) => (
            <Card {...item} key={index} className={cardClass} />
          ))}
        </div>
      </div>
    </section>
  );
};

const SectionCards1 = ({
  title,
  description = "",
  items,
  Card,
  bodyClass = "",
  cardClass = "",
}) => {
  return (
    <div className="container mx-auto py-7 md:py-20 px-3 md:px-[123px] space-y-[20px] md:space-y-[80px] bg-[#F4F5F6] rounded-[20px] xl:w-[1336px]">
      <SectionCards.Title
        title={title}
        className="mx-auto text-[32px] md:text-[50px] font-medium leading-[40px] md:leading-[62.5px] tracking-[-1.5px] text-center max-w-[1180px] text-[#3d434c]"
        secondClassName="text-[#3d434c]"
      />

      <div className={bodyClass}>
        {items.map((item, index) => (
          <Card context={"v4"} data={item} key={index} className={`slidein slidein-delay-${index} ` + cardClass} />
        ))}
      </div>
    </div>
  );
};

const SectionCards2 = ({
  title,
  description = "",
  items,
  Card,
  cardClass = "",
}) => {
  //console.log( items )
  return (
    <section className="pb-24">
      <div className="container mx-auto ">
        <div className="text-center space-y-[32px] pt-[8px] md:pt-[92px] mb-12 md:mb-[100px]">
          <SectionCards.Title
            title={title}
            className="mx-auto text-[46px] md:text-[72px] font-semibold leading-[60px] md:leading-[90px] tracking-[-3px]"
            secondClassName="text-transparent bg-clip-text bg-gradient-to-r from-[#4A72FF]  to-[#1B4DFF]"
          />
          <SectionCards.Description
            className="max-w-[590px] font-normal mx-auto text-[22px] leading-[40px] tracking-[-1px]"
            description={description}
          />
        </div>
        <div className="flex flex-col md:flex-row gap-[50px] max-w-[1280px] mx-auto mt-[70px] md:justify-center">
          {items.map((item, index) => (
            <Card
              context={"v2"}
              data={item}
              key={index}
              className={`max-w-[300px] slidein slidein-delay-${index} ` + cardClass}
            />
          ))}
          {/* <div className="hidden md:block absolute w-[1px] left-[50%] top-0 bottom-0 lp-border"></div>
          <div className="hidden md:block absolute h-[1px] left-0 top-[50%]  right-0 lp-border"></div> */}
        </div>
      </div>
    </section>
  );
};

const SectionCards3 = ({
  title,
  items,
  Card,
  bodyClass = "",
  cardClass = "",
}) => {
  // arrow-1.svg

  const cards0 = items.map((item, index) => (
    <Card data={{...item, index:index}} context="v3" key={index}  className={cardClass} />
  ));
  const cards = [];

  for (const key in cards0) {
    cards.push(cards0[key]);
    cards.push(<SectionCards3.Arrow key={"s" + key} />);
  }

  cards.pop();
  if (cards.length > 0 ) {
    return (
      <section className="py-12 md:py-[106px]">
        <div className="container mx-auto space-y-[60px] ">
          <SectionCards.Title
            title={title}
            className="font-medium leading-[40px] md:leading-[62px] text-[32px] md:text-[50px] tracking-[-0.03em] text-center text-[#1A1D21]"
          />
  
          <div className={bodyClass}>{cards}</div>
        </div>
      </section>
    );
  }
};

SectionCards3.Arrow = () => {
  return (
    <div className=" lg:flex flex-row items-center justify-center md:h-[118px]">
      <img
        src="/arrow-1.svg"
        alt="arrow"
        //height="16"
        //width="16"
        className="hidden md:block mx-auto mt-[16px] "
      />
       <img
        src="/arrow-2.svg"
        alt="arrow"
        //height="16"
        //width="16"
        className="block mx-auto my-[16px] md:hidden "
      />
    </div>
  );
};

SectionCards.Title = ({
  title,
  className,
  secondClassName = "text-blue",
  ...props
}) => {
  const t = pipesToSpan(title, secondClassName);
  const t1 = t.replace("  " , "<br/>")
  return (
    <h2
      className={className}
      dangerouslySetInnerHTML={{ __html: t1 }}
      {...props}
    />
  );
};

SectionCards.Description = ({ description, className }) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};

SectionCards.Body = ({ Card, items, className, cardClass }) => {
  const cards = items.map((item, index) => (
    <Card {...item} key={index} index={index} className={cardClass} />
  ));
  return <div className={className}>{cards}</div>;
};

export default Landing;

export const query = graphql`
  query lpUseCase2024($id: String!) {
    node: nodeLandingPage2024(id: { eq: $id }) {
      id
      title

      block2Description: field_description2
      block2Title: field_title3
      block3Title: field_title2
      block1Title: field_title

      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path {
        alias
      }

      relationships {
        hero: field_hero {
          ...ParagraphGenericBlock
        }
        items: field_items {
          ...ParagraphTitleTextImage
        }
        blocks: field_paragraph_multi {
          ...ParagraphTitleTextImage
        }
        cards: field_paragraph_repeter {
          ...ParagraphTitleTextImage
        }
        block: field_content_block {
          ...ParagraphGenericBlock
        }
        faq: field_faq {
          ...ParagraphFaq
        }
        hubspot: field_hubspot {
          ...ParagraphHubspotContent
        }
        testimonials: field_testimonials {
          ...BlockContentTestimonial
        }
        zebra: field_cards_section {
          ...ParagraphZebra
        }
      }
    }
  }
`;
